// import moment from 'moment';
import React from 'react';

// import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { SortableHandle } from 'react-sortable-hoc';

import _find from 'lodash/find';
import _get from 'lodash/get';

import Overlay from 'react-bootstrap/Overlay';

import acl from 'tools/acl';
import artworkUtils from 'tools/artwork';

import { updateFileArtwork } from 'actions/ArtworkActions';
import { updateFilePack } from 'actions/PackActions';

import PlayerCover from 'components/Cover/PlayerCover';

import CommunSVGs from 'svgs/commun';

import { LIST_MODE } from '../FolderDisplaySwitch';
import './FolderFile.scss';
import FolderFileHandler from './FolderFileHandler';
import FolderTitle from './FolderTitle';
import MicroMenu from './MicroMenu';

const DragHandle = SortableHandle(({ className, file, editTitle, disabled, handleSubmit }) => (
  <FolderFileHandler
    className={className}
    file={file}
    editTitle={editTitle}
    disabled={disabled}
    handleSubmit={handleSubmit}
  />
));

const SimpleDragHandle = SortableHandle(() => (
  <CommunSVGs.orderVertical className="Folder-Dragable drag" width="16" fill="var(--primary)" />
));

interface Props {
  file: Object;
  noLazy: boolean;
  artwork: Object;
  type: string;
  data: Object;
  className: string;
  id: number;
  userProfile: Object;
  userArtworks: [];
  withMenu: boolean;
  dragable: boolean;
  displayMode: string;
  order: number;
  totalFiles: number;
  visitorArtworkAccesses: [];
  visitorPackAccesses: [];
}

function FolderFile({
  data,
  artwork,
  file,
  noLazy,
  className,
  id,
  type,
  userProfile,
  userArtworks,
  withMenu,
  dragable,
  displayMode,
  order,
  totalFiles,
  visitorArtworkAccesses,
  visitorPackAccesses,
}: Props) {
  const dispatch = useDispatch();
  // const { i18n } = useTranslation('folderfile');
  const [editTitle, setEditTitle] = React.useState(false);
  const [showCover, setShowCover] = React.useState(false);
  const overlay = React.useRef();

  const isAuthor = acl.isAuthor(userProfile, artwork);
  let isNew = _get(artwork, 'artworkAccess.isFollower') && file.isRead === false;
  if (!isAuthor && file.isRead === null) {
    // not followed, subscribe or purchased
    if (type !== 'pack') {
      const oa = _find(visitorArtworkAccesses, a => a.id === id || data.id);
      const readingDate = _get(oa, 'readingDate');
      isNew = !readingDate || readingDate < file.createdAt;
    } else {
      const op = _find(visitorPackAccesses, p => p.id === id || data.id);
      const readingDate = _get(op, 'readingDate');
      isNew = !readingDate || readingDate < file.file.createdAt;
    }
  }

  const handleSubmitTitle = e => {
    if (e.keyCode === 13) {
      // enter
      setEditTitle(false);
      const updateFile = { id: file.file.id, name: e.target.value };
      if (type !== 'pack') {
        dispatch(updateFileArtwork(updateFile, artwork.id));
      } else {
        dispatch(updateFilePack(updateFile, data.id));
      }
    }
  };

  const playerParams = { type, id: data.id, fileId: file.file.id, artworkId: artwork ? artwork.id : data.id };
  if (displayMode !== LIST_MODE) {
    return (
      <div className={`FolderFile FolderFile-grid ${className}-file`}>
        <div className={`FolderFile-header ${className}-file-header`}>
          <PlayerCover
            src={artworkUtils.getFileUrl(file.file, 100)}
            alt={file.file.name}
            key={file.file.id}
            playerParams={playerParams}
            className={`${className}-cover`}
            noLazy={!!noLazy}
            noClass
          />
          {isNew && <CommunSVGs.New className="Folder-badge-top" width="98" height="22" />}
          {file.file.isFree && <CommunSVGs.Free className="Folder-badge-bottom" width="98" height="22" />}
          {withMenu && (
            <MicroMenu
              file={file}
              artwork={artwork}
              type={type}
              data={data}
              userProfile={userProfile}
              userArtworks={userArtworks}
              onEditTitle={() => setEditTitle(true)}
            />
          )}
        </div>
        <DragHandle
          className={className}
          file={file}
          editTitle={editTitle}
          disabled={!dragable}
          handleSubmit={handleSubmitTitle}
        />
      </div>
    );
  }
  // LIST_MODE
  const page = `${totalFiles - order || 0}`.padStart(3, '0');
  return (
    <div className={`FolderFile FolderFile-list ${className}-file col-12`}>
      <div className="FolderFile-page">{page}</div>
      {dragable && <SimpleDragHandle />}
      <div
        className={`FolderFile-text flowfont-regular ${className}-title`}
        onMouseOver={() => setShowCover(true)}
        onMouseOut={() => setShowCover(false)}
        onFocus={() => setShowCover(true)}
        onBlur={() => setShowCover(false)}
        ref={overlay}
      >
        <FolderTitle file={file} editTitle={editTitle} playerParams={playerParams} handleSubmit={handleSubmitTitle} />
      </div>
      {file.file.isFree && <span className="mx-2">Free</span>}
      <div className="FolderFile-info">
        {/* {file.file.createdAt && (
          <p className={`Folder-date flowfont-thin text-neutralSubtle ${className}-subtitle`}>
            {moment(file.file.createdAt).locale(i18n.language).fromNow()}
          </p>
        )} */}
        {!!file.file.feedbackCount && (
          <span>
            {artworkUtils.formatCount(file.file.feedbackCount)}{' '}
            <CommunSVGs.NiceWork width="16" color="var(--neutralSubtle)" transform="translate(0,-2)" />
          </span>
        )}
        {!!file.file.commentCount && (
          <span className="FolderFile-counters">
            {artworkUtils.formatCount(file.file.commentCount)}{' '}
            <CommunSVGs.Bubble width="16" color="var(--neutralSubtle)" transform="translate(0,-2)" />
          </span>
        )}
        {!!file.file.readCount && (
          <span>
            {artworkUtils.formatCount(file.file.readCount)}{' '}
            <CommunSVGs.Readfull width="16" color="var(--neutralSubtle)" transform="translate(0,-2)" />
          </span>
        )}
      </div>
      {withMenu && (
        <MicroMenu
          file={file}
          artwork={artwork}
          type={type}
          data={data}
          userProfile={userProfile}
          userArtworks={userArtworks}
          onEditTitle={() => setEditTitle(true)}
          className="list"
        />
      )}
      <Overlay
        placement="top-start"
        show={!!showCover}
        rootClose
        target={overlay.current}
        onHide={() => setShowCover(false)}
        onExited={() => setShowCover(false)}
      >
        <div className="FolderFile-cover-overlay">
          <PlayerCover
            src={artworkUtils.getFileUrl(file.file, 100)}
            alt={file.file.name}
            key={file.file.id}
            playerParams={{ type, id: data.id, fileId: file.file.id, artworkId: artwork ? artwork.id : data.id }}
            className={`${className}-cover`}
            noLazy={!!noLazy}
            noClass
          />
        </div>
      </Overlay>
    </div>
  );
}

export default React.memo(FolderFile);
