/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useState } from 'react';

import { useDispatch } from 'react-redux';

import { showPlayer } from 'actions/PlayerActions';

type Props = {
  file: Object,
  editTitle: Boolean,
  playerParams: Object,
  handleSubmit(): void,
};

export default function FolderTitle({ file, editTitle, playerParams, handleSubmit }: Props) {
  const dispatch = useDispatch();
  const [title, setTitle] = useState(file.file.name);

  React.useEffect(() => {
    setTitle(file.file.name);
  }, [editTitle, file]);

  if (editTitle) {
    return (
      <input
        className="form-control"
        type="text"
        value={title}
        onChange={e => setTitle(e.target.value)}
        onKeyDown={handleSubmit}
      />
    );
  }
  if (playerParams) {
    return (
      <div onClick={() => dispatch(showPlayer(playerParams))} role="presentation" className="cursor-pointer">
        {file.file.name}
      </div>
    );
  }
  return file.file.name;
}
