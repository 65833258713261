import moment from 'moment';

import React from 'react';

import { useTranslation } from 'react-i18next';

import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

import FolderTitle from './FolderTitle';

type Props = {
  file: Object,
  className: String,
  editTitle: Boolean,
  disabled: Boolean,
  handleSubmit: Function,
};
export default function FolderFileHandle({ className, file, editTitle, disabled, handleSubmit }: Props) {
  const { i18n } = useTranslation('folder');
  return (
    <div className={`FolderFile-footer ${className}-file-footer ${disabled ? '' : 'Folder-Dragable'}`}>
      <OverlayTrigger
        placement="bottom"
        trigger={['hover', 'focus']}
        delay={{ show: 1000 }}
        overlay={<Tooltip className="FolderFile-tooltip">{file.file.name}</Tooltip>}
      >
        <p className={`FolderFile-text flowfont-regular ${className}-title`}>
          <FolderTitle file={file} editTitle={editTitle} handleSubmit={handleSubmit} />
        </p>
      </OverlayTrigger>
      {file.file.createdAt && (
        <p className={`Folder-date flowfont-thin text-neutralSubtle ${className}-subtitle`}>
          {moment(file.file.createdAt).locale(i18n.language).fromNow()}
        </p>
      )}
    </div>
  );
}
