import React from 'react';

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import CommunSVGs from 'svgs/commun';

import './InfoModal.scss';

type Props = {
  title: string,
  text: string,
  buttonLabel: string,
  onClose: Function,
};

const InfoModal = ({ title, text, buttonLabel, onClose }: Props) => {
  const [show, setShow] = React.useState(true);

  const handleClose = () => {
    if (onClose) onClose();
    setShow(false);
  };

  return (
    <Modal show={show} onHide={handleClose} centered dialogClassName="InfoModal">
      <Modal.Body>
        <div className="InfoModal-content text-center">
          <div className="InfoModal-title h3 flowfont-title text-secondary text-center">{title}</div>
          <p className="InfoModal-description flowfont-regular text-neutral text-center">{text}</p>
          <p className="InfoModal-exit text-center">
            <Button onClick={handleClose} variant="primary">
              <CommunSVGs.ArrowLeft height="24" fill="#FFFFFF" /> {buttonLabel || 'Go back'}
            </Button>
          </p>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default InfoModal;
