import React from 'react';

import { SortableElement } from 'react-sortable-hoc';

import artworkUtils from 'tools/artwork';

import FolderFile from '../../../FolderFile';

const SortableItem = SortableElement(
  ({
    data,
    artwork,
    file,
    userProfile,
    visitorArtworkAccesses,
    visitorPackAccesses,
    disabled,
    displayMode,
    order,
    totalFiles,
  }) => (
    <FolderFile
      className="Folder-galleryImage"
      type="pack"
      data={data}
      artwork={artwork}
      file={file}
      noLazy={artworkUtils.isUnlocked(file.file)}
      userProfile={userProfile}
      dragable={!disabled}
      withMenu
      visitorArtworkAccesses={visitorArtworkAccesses}
      visitorPackAccesses={visitorPackAccesses}
      displayMode={displayMode}
      totalFiles={totalFiles}
      order={order}
    />
  ),
);

export default SortableItem;
