import axios from 'axios';
import { saveAs } from 'file-saver';

import React from 'react';

import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import _filter from 'lodash/filter';
import _get from 'lodash/get';

import Overlay from 'react-bootstrap/Overlay';

import acl from 'tools/acl';

import { updateFileArtwork, deleteFileArtwork } from 'actions/ArtworkActions';
import { updateFilePack, deleteFilePack } from 'actions/PackActions';

import CommunSVGs from 'svgs/commun';

type Props = {
  file: Object,
  artwork: Object,
  type: string,
  data: Object,
  userProfile: Object,
  userArtworks: [],
  onEditTitle(): void,
  className: String,
};

export default function MicroMenu({
  file,
  artwork,
  type,
  data,
  userProfile,
  userArtworks,
  onEditTitle,
  className,
}: Props) {
  const dispatch = useDispatch();
  const { t } = useTranslation('folder');
  const [showMenu, setShowMenu] = React.useState(false);
  const overlay = React.useRef();

  const isAuthor = acl.isAuthor(userProfile, artwork);

  let artworkFlows = _get(artwork, 'packs') || [];
  if (type === 'pack') {
    artworkFlows = artworkFlows.filter(p => `${p.id}` !== `${data.id}`);
    if (artwork.isFlow) {
      artworkFlows = [artwork, ...artworkFlows];
    }
  }
  const visitorArtworkAccess = _filter(userArtworks, a => a.isFlow && a.id !== (artwork ? artwork.id : data.id)) || [];

  const toggleFree = () => {
    const updateFile = { id: file.file.id, isFree: !file.file.isFree };
    if (type !== 'pack') {
      dispatch(updateFileArtwork(updateFile, artwork.id));
    } else {
      dispatch(updateFilePack(updateFile, data.id));
    }
    setShowMenu(false);
  };

  const handleDelete = () => {
    if (type !== 'pack') {
      dispatch(deleteFileArtwork(file.file.id, artwork.id));
    } else {
      dispatch(deleteFilePack(file.file.id, data.id));
    }
    setShowMenu(false);
  };

  const handleDownload = () => {
    const fileName = `${file.file.name}.jpg`;
    // console.log(fileName);
    axios.get(file.file.contentUrl, { responseType: 'blob', crossdomain: true }).then(response => {
      saveAs(response.data, fileName);
    });
    setShowMenu(false);
  };

  const handleMoveTo = flow => () => {
    const updateFile = { id: file.file.id };
    if (flow.artwork) updateFile.moveToPackId = flow.id;
    else updateFile.moveToFlowId = flow.id;
    if (type !== 'pack') {
      dispatch(updateFileArtwork(updateFile, artwork.id));
    } else {
      dispatch(updateFilePack(updateFile, data.id));
    }
    setShowMenu(false);
  };
  return (
    <>
      <span
        className={`Folder-MicroMenu-icon ${className || ''}`}
        ref={overlay}
        onClick={() => setShowMenu('main')}
        role="presentation"
      >
        <CommunSVGs.MicroMenu height="30" fill="var(--white)" />
      </span>
      <Overlay
        placement="left-start"
        trigger="click"
        target={overlay.current}
        show={!!showMenu}
        rootClose
        onHide={() => setShowMenu(false)}
        onExited={() => setShowMenu(false)}
      >
        {({ ...props }) => (
          <div className={`Folder-MicroMenu-menu ${showMenu !== 'main' && 'large'}`} {...props}>
            <ul className={`Folder-MicroMenu-main ${showMenu === 'main' ? 'show' : 'hide'} flowfont-regular`}>
              <li onClick={handleDownload} role="presentation">
                Download
              </li>
              {isAuthor && (
                <>
                  <li onClick={handleDelete} role="presentation">
                    Delete
                  </li>
                  <li
                    onClick={() => {
                      setShowMenu(false);
                      onEditTitle();
                    }}
                    role="presentation"
                  >
                    <div className="my-auto">Edit title</div>
                  </li>
                  {(artworkFlows.length > 0 || visitorArtworkAccess.length > 0) && (
                    <li onClick={() => setShowMenu('move')} role="presentation">
                      <div className="d-flex align-items-center my-auto">
                        <span>Move to</span>{' '}
                        <CommunSVGs.RightRoll className="ml-auto" height="24" width="16" color="var(--primary)" />
                      </div>
                    </li>
                  )}
                  <li onClick={toggleFree} role="presentation">
                    Free{' '}
                    <CommunSVGs.Checked
                      className="ml-auto"
                      height="16"
                      width="16"
                      color={file.file.isFree ? 'var(--primary)' : 'var(--halfDistant)'}
                    />
                  </li>
                </>
              )}
            </ul>
            {isAuthor && (
              <>
                <ul className={`Folder-MicroMenu-secondary  ${showMenu === 'move' ? 'show' : 'hide'} flowfont-regular`}>
                  {artworkFlows.map(flow => (
                    <li key={flow.id} onClick={handleMoveTo(flow)} role="presentation">
                      {flow.isFlow && (flow.flowName || t('global:default-flow-name'))}
                      {!flow.isFlow && flow.name}
                    </li>
                  ))}
                </ul>
              </>
            )}
          </div>
        )}
      </Overlay>
    </>
  );
}
