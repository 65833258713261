import React from 'react';

import { SortableContainer } from 'react-sortable-hoc';

import { LIST_MODE } from '../../../FolderDisplaySwitch';
import SortableItem from './SortableItem';

const SortableList = SortableContainer(
  ({
    files,
    artwork,
    data,
    userProfile,
    visitorArtworkAccesses,
    visitorPackAccesses,
    disabled,
    displayMode,
    totalFiles,
  }) => {
    return (
      <div
        className={`d-flex flex-wrap align-items-center justify-content-around ${
          displayMode !== LIST_MODE ? 'flex-row' : 'flex-column'
        }`}
      >
        {files.map((file, index) => (
          <SortableItem
            key={`item-${file.file.id}`}
            index={index}
            order={index}
            data={data}
            artwork={artwork}
            file={file}
            userProfile={userProfile}
            visitorArtworkAccesses={visitorArtworkAccesses}
            visitorPackAccesses={visitorPackAccesses}
            disabled={disabled}
            displayMode={displayMode}
            totalFiles={totalFiles}
          />
        ))}
      </div>
    );
  },
);

export default SortableList;
