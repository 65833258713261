import axios from 'axios';
import { saveAs } from 'file-saver';
import JSZip from 'jszip';

import React, { useState } from 'react';

import { connect } from 'react-redux';
import Select from 'react-select';

import ProgressBar from 'react-bootstrap/ProgressBar';

import flowService from 'services/artwork';
import packService from 'services/pack';

import { showNotification } from 'actions/NotificationsActions';

import hydraTool from '../../../tools/hydra';

function urlToBlobPromise(url) {
  return new Promise((resolve, reject) => {
    return axios.get(url, { responseType: 'blob', crossdomain: true }).then(response => {
      if (response.status !== 200) {
        reject(response);
      } else {
        resolve(response.data);
      }
    });
  });
}

type Props = {
  artwork: Object,
  type: String,
  data: Object,
  showNotificationAction: Function,
};

function FolderDownload({ artwork, data, type, showNotificationAction }: Props) {
  const [value, setValue] = useState(null);

  const files = type === 'flow' ? data.artworkFiles : data.packFiles;
  if (!files || files.length === 0) return null;

  const download = async format => {
    const zip = new JSZip();

    let allFiles = [];
    if (type === 'flow') {
      allFiles = hydraTool.getItems(await flowService.getFiles(data.id, 'all'));
    } else if (type === 'pack') {
      allFiles = hydraTool.getItems(await packService.getFiles(data.id, 'all'));
    }

    // console.log(allFiles, data);
    if (!files || files.length === 0) return;
    showNotificationAction({
      level: 'info',
      title: 'Download process started.',
      message: 'It may take a -long- time.',
    });
    setValue(0);

    /*
    let prefix = 'Flow';
    if (type === 'pack') {
      const packInfo = _find(artwork.packs, p => `${p.id}` === `${data.id}`);
      if (packInfo) prefix = packInfo.name;
    }
    const zipName = `${prefix} - ${artwork.uniqueName} - ${artwork.author.username}.${format || 'zip'}`;
    // console.log(zipName);

    _forEach(files, aFile => {
      const fileName = `${prefix} - ${artwork.uniqueName} - ${artwork.author.username} - ${aFile.file.name}.jpg`;
      // console.log(fileName);
      zip.file(fileName, urlToBlobPromise(aFile.file.contentUrl), {
        binary: true,
      });
    });
    */

    let prefix = '';
    if (type === 'pack') {
      const packInfo = artwork.packs?.find(p => `${p.id}` === `${data.id}`);
      if (packInfo) prefix = packInfo.name;
    }
    const zipName = `${artwork.uniqueName} - ${prefix} by ${artwork.author.username} on Flowfo.${format || 'zip'}`;
    // console.log(zipName);

    allFiles?.forEach(aFile => {
      const fileName = `${aFile.file.name}.jpg`;
      // console.log(fileName);
      zip.file(fileName, urlToBlobPromise(aFile.file.contentUrl), {
        binary: true,
      });
    });

    // when everything has been downloaded, we can trigger the dl
    zip
      .generateAsync({ type: 'blob' }, function updateCallback(metadata) {
        // let msg = `progression : ${metadata.percent.toFixed(2)} %`;
        // if (metadata.currentFile) {
        //   msg += `, current file = ${metadata.currentFile}`;
        // }
        // // eslint-disable-next-line no-console
        // console.log(msg, metadata.percent || 0);
        const progress = metadata.percent || 0;
        setValue(Math.floor(progress));
      })
      .then(
        blob => {
          saveAs(blob, zipName);
          setValue(null);
        },
        e => {
          // eslint-disable-next-line no-console
          console.log(e);
          showNotificationAction({
            level: 'error',
            title: 'Download failed !',
          });
          setValue(null);
        },
      );
  };

  const options = [
    { label: 'Download all (zip)', value: 'zip' },
    { label: 'Download all (cbr)', value: 'cbr' },
  ];
  return (
    <div className="Folder-gallery-download">
      <Select
        className="Folder-gallery-download-select"
        value={null}
        // defaultValue={defaultValue}
        options={options}
        classNamePrefix="Select"
        placeholder="Download all"
        theme={theme => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary25: 'var(--secondary)',
            primary: 'var(--primary)',
          },
        })}
        onChange={e => {
          download(e.value);
        }}
      />
      {value !== null && (
        <ProgressBar className="Folder-gallery-download-progress" animated now={value} label={`${value}%`} />
      )}
    </div>
  );
}

const mapDispatchToProps = dispatch => ({
  showNotificationAction: params => dispatch(showNotification(params)),
});

export default connect(null, mapDispatchToProps)(FolderDownload);
