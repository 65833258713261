/* eslint-disable react/jsx-props-no-spreading */
import arrayMove from 'array-move';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import FilePondPluginImageValidateSize from 'filepond-plugin-image-validate-size';
import moment from 'moment';

import React, { Component } from 'react';

import { FilePond, registerPlugin } from 'react-filepond';
import Helmet from 'react-helmet';
import { withTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroller';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { AutoSizer } from 'react-virtualized';

import _get from 'lodash/get';

import acl from 'tools/acl';
import artworkUtils from 'tools/artwork';
import seo from 'tools/seo';

import { reorderFileArtwork, getArtworkFiles } from 'actions/ArtworkActions';

import Header from 'components/Folder/Flow/Header';
import Loading from 'components/Loading';
import InfoModal from 'components/Modal/InfoModal';

import FolderDisplaySwitch, { GRID_MODE, LIST_MODE } from '../../FolderDisplaySwitch';
import FolderDownload from '../../FolderDownload';
import SortableList from './partials/SortableList';

registerPlugin(
  FilePondPluginImageExifOrientation,
  FilePondPluginImagePreview,
  FilePondPluginFileValidateSize,
  FilePondPluginImageValidateSize,
  FilePondPluginFileValidateType,
);

const urlApi = process.env.RAZZLE_URL_API;

type Props = {
  data: Object,
  files: Array,
  totalFiles: Number,
  lastFiles: Number,
  isFetching: Boolean,
  match: Object,
  userProfile: Object,
  artwork: Object,
  visitorArtworkAccesses: [],
  visitorPackAccesses: [],
  getArtworkFilesAction: Function,
  reorderFileArtworkAction: Function,
};

class Page extends Component<Props> {
  constructor(props) {
    super(props);
    this.state = {
      items: null,
      page: 1,
      nbUploaded: 0,
      lastUpload: new Date(),
      showReorderModal: false,
      displayMode: GRID_MODE,
    };
  }

  componentDidMount() {
    const { files, userProfile, artwork, getArtworkFilesAction } = this.props;
    const isAuthor = acl.isAuthor(userProfile, artwork);

    this.setState({
      items: files,
      lastUpload: new Date(),
      nbUploaded: 0,
      displayMode: isAuthor ? LIST_MODE : GRID_MODE,
    });
    getArtworkFilesAction(artwork.id, 1);
  }

  componentDidUpdate(prevProps) {
    const { files } = this.props;
    if (files !== prevProps.files) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ items: files });
    }
  }

  onProcessFile = (error, fileData) => {
    if (!error) {
      const { artwork, totalFiles, reorderFileArtworkAction } = this.props;
      const { items: files, lastUpload, nbUploaded: totalUploaded } = this.state;
      const file = JSON.parse(fileData.serverId);

      let sortNumber = totalFiles + totalUploaded + 1;
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < files.length; i++) {
        const isNewerFile = moment(lastUpload).diff(files[i].createdAt) < 0;
        // console.log(isNewerFile, files[i].sortNumber, sortNumber);
        if (isNewerFile && files[i].file.name.localeCompare(file.name) > 0) {
          // eslint-disable-next-line prefer-destructuring
          sortNumber = files[i].sortNumber;
          break;
        }
      }
      reorderFileArtworkAction(file.id, sortNumber, artwork.id);

      this.pond.removeFile(fileData.id);
      this.setState(({ items, nbUploaded }) => {
        items.unshift({ file });
        return { items, nbUploaded: nbUploaded + 1 };
      });
    }
  };

  onSortEnd = sort => {
    const { artwork, reorderFileArtworkAction } = this.props;
    const { items } = this.state;
    if (sort.oldIndex !== sort.newIndex) {
      reorderFileArtworkAction(items[sort.oldIndex].file.id, items[sort.newIndex].sortNumber, artwork.id);
      this.setState(state => ({
        items: arrayMove(state.items, sort.oldIndex, sort.newIndex),
      }));
    }
  };

  loadMore = async () => {
    const { artwork, isFetching, lastFiles, getArtworkFilesAction } = this.props;
    const { page } = this.state;
    const currentPage = page + 1;
    if (isFetching || currentPage > lastFiles) {
      return;
    }
    this.setState({ page: currentPage });
    await getArtworkFilesAction(artwork.uniqueName, currentPage);
  };

  render() {
    const {
      data,
      artwork,
      match,
      userProfile,
      visitorArtworkAccesses,
      visitorPackAccesses,
      totalFiles,
      t,
    } = this.props;
    const { items, displayMode, showReorderModal } = this.state;
    const token = acl.getToken();
    const isAuthor = acl.isAuthor(userProfile, artwork);

    return (
      <div className="">
        <Helmet>
          {seo.title(
            `${_get(artwork, 'flowName') || t('global:default-flow-name')} - ${_get(artwork, 'name')} by ${_get(
              artwork,
              'author.username',
            )} - Flowfo`,
          )}
          {seo.description(_get(artwork, 'flowDescription') || t('global:default-flow-description'))}
          {seo.image(artworkUtils.getCoverUrl(data))}
          {seo.noIndex()}
        </Helmet>
        <Header artwork={data} userProfile={userProfile} className="Folder-gallery-header" />

        <div className="Folder-gallery-actions">
          <div className="Folder-gallery-actions-buttons">
            <FolderDownload data={data} artwork={artwork} type="flow" />
            <FolderDisplaySwitch
              value={displayMode}
              onChange={checked => {
                this.setState({ displayMode: checked ? LIST_MODE : GRID_MODE });
              }}
            />
          </div>
          {isAuthor && items && displayMode === GRID_MODE && (
            <span
              className="Folder-uploader-help"
              onClick={() => this.setState({ showReorderModal: true })}
              role="presentation"
            >
              {t('folder:to-reorder')} <img src="/assets/icons/order.svg" alt="move" width="12" height="12" />
            </span>
          )}
        </div>
        {isAuthor && items && (
          <div className="Folder-uploader">
            <FilePond
              ref={ref => {
                this.pond = ref;
              }}
              name="upload"
              allowMultiple
              acceptedFileTypes={['image/png', 'image/jpg', 'image/jpeg']}
              imageValidateSizeMaxWidth={3000}
              imageValidateSizeMaxHeight={3000}
              imageValidateSizeMinWidth={100}
              imageValidateSizeMinHeight={100}
              maxFileSize="3MB"
              server={{
                url: `${urlApi}/files/artwork/${match.params.id}`,
                process: {
                  headers: { Authorization: `Bearer ${token}` },
                },
                revert: null,
              }}
              maxParallelUploads={1}
              onprocessfile={this.onProcessFile}
              onprocessfiles={() => this.setState({ lastUpload: new Date() })}
              labelIdle={t('folder:drag-and-drop-label')}
            />
          </div>
        )}
        <div className="Folder-gallery">
          {items && items.length > 0 && (
            <AutoSizer>
              {({ height, width }) => (
                <InfiniteScroll
                  pageStart={1}
                  loadMore={this.loadMore}
                  hasMore={items.length < totalFiles}
                  loader={<Loading key={0} />}
                  initialLoad={false}
                  style={{ width, height }}
                >
                  <SortableList
                    axis={displayMode !== LIST_MODE ? 'xy' : 'y'}
                    pressDelay={100}
                    files={items}
                    data={data}
                    artwork={data}
                    userProfile={userProfile}
                    visitorArtworkAccesses={visitorArtworkAccesses}
                    visitorPackAccesses={visitorPackAccesses}
                    useDragHandle
                    onSortEnd={this.onSortEnd}
                    disabled={!isAuthor}
                    disableAutoscroll
                    displayMode={displayMode}
                    totalFiles={totalFiles}
                  />
                  {isAuthor && (
                    <>
                      <p className="text-neutralSubtle text-center mt-8">
                        <br />
                        To be played correctly, the last files
                        <br /> have to be on top of the gallery.
                      </p>
                      <img
                        className="d-flex mx-auto"
                        src="/assets/images/galleryOrderTip.svg"
                        alt="gallery-order-tip"
                      />
                    </>
                  )}
                  <p className="text-neutralSubtle">
                    {' '}
                    <br /> <br /> <br />{' '}
                  </p>
                </InfiniteScroll>
              )}
            </AutoSizer>
          )}
          {!items && <Loading key={0} />}
          {items && items.length === 0 && (
            <p className="text-neutral text-center mt-5 mb-5">
              {t('global:no-content')}
              {isAuthor && (
                <>
                  <br /> <p className="text-neutral text-center mt-8">{t('folder:add-first-file')}</p>
                  <img className="d-flex mx-auto" src="/assets/images/galleryOrderTip.svg" alt="gallery-order-tip" />
                </>
              )}
            </p>
          )}
        </div>
        {showReorderModal && (
          <InfoModal
            title={t('global:modal-reorder-title')}
            text={<img src="/assets/images/reorder.gif" alt="reorder" />}
            onClose={() => this.setState({ showReorderModal: false })}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  files: state.artwork.files,
  totalFiles: state.artwork.totalFiles,
  lastFiles: state.artwork.lastFiles,
  isFetching: _get(state.artwork, 'isFetching.getArtworkFiles'),
});

const mapDispatchToProps = dispatch => ({
  reorderFileArtworkAction: (fileId, sortNumber, artworkId) =>
    dispatch(reorderFileArtwork(fileId, sortNumber, artworkId)),
  getArtworkFilesAction: (artworkId, page) => dispatch(getArtworkFiles(artworkId, page)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withTranslation('flow')(Page)));
