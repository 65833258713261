/* eslint-disable react/jsx-props-no-spreading */

/* eslint-disable jsx-a11y/label-has-for */
import React, { PureComponent } from 'react';

import Switch from 'react-switch';

import './Switch.scss';

type Props = {
  label: string,
  helperText: string,
  children: any,
  className: string,
  field: Object,
  form: Object,
  iconOn: string,
  iconOff: string,
  disabled: Boolean,
  onChange: Function,
};

export const SWITCH_DEFAULT_OPTIONS = {
  onColor: '#ad77ad',
  onHandleColor: 'var(--secondary)',
  handleDiameter: 18,
  uncheckedIcon: false,
  checkedIcon: false,
  boxShadow: '0px 1px 5px rgba(0, 0, 0, 0.6)',
  activeBoxShadow: '0px 0px 1px 5px rgba(0, 0, 0, 0.2)',
  height: 14,
  width: 30,
};

class CustomSwitch extends PureComponent<Props> {
  render() {
    const {
      iconOn,
      iconOff,
      label,
      helperText,
      className,
      children,
      disabled,
      onChange,
      field, // { name, value, onChange, onBlur }
      form: { touched, errors, values, setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
    } = this.props;

    return (
      <div className={`Input Switch form-group ${className || ''}`}>
        <div className="Switch-content">
          {label && (
            <label className="Input-label Switch-label flowfont-medium text-uppercase mr-2" htmlFor={field.name}>
              {label}
            </label>
          )}

          <Switch
            {...SWITCH_DEFAULT_OPTIONS}
            onChange={checked => {
              setFieldValue(field.name, checked, false); // /** last arg tells formik not to run validation and make this change synchronously  */
              if (onChange) onChange();
            }}
            checked={!!values[field.name]}
            className="Switch-field"
            disabled={disabled}
          />

          {iconOn && (
            <img
              src={`/assets/icons/${values[field.name] || !iconOff ? iconOn : iconOff}`}
              alt=""
              className="Switch-icon"
            />
            // <img
            //   src={`/assets/icons/-18${values[field.name] ? '' : '-grey'}.png`}
            //   alt="Sexual content"
            //   className="Switch-icon"
            // />
          )}
        </div>

        {helperText && !errors[field.name] && <small className="form-text text-muted">{helperText}</small>}

        {touched[field.name] && errors[field.name] && (
          <div className="Input-error flowfont-medium text-danger">{errors[field.name]}</div>
        )}

        {children && children}
      </div>
    );
  }
}

export default CustomSwitch;
