/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

import { useTranslation } from 'react-i18next';
import Switch from 'react-switch';

import { SWITCH_DEFAULT_OPTIONS } from '../../Form/Switch';

export const GRID_MODE = 'grid';
export const LIST_MODE = 'list';

type Props = {
  value: string,
  onChange(): void,
};

function FolderDisplaySwitch({ value, onChange }: Props) {
  const { t } = useTranslation('folder');
  return (
    <div className="Folder-display-switch d-flex align-items-center justify-content-center">
      <span className="mr-2">{t('display-list')}</span>
      <Switch {...SWITCH_DEFAULT_OPTIONS} onChange={onChange} checked={value !== GRID_MODE} className="Switch-field" />
    </div>
  );
}
export default React.memo(FolderDisplaySwitch);
